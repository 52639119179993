import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import AddBet from '../AddBets/AddBet';
import AddBank from '../AddBank/AddBank';

const DashboardHeader = () => {

  const [addApostasOpen, setAddApostasOpen] = useState(false);
  const [addBancaOpen, setAddBancaOpen] = useState(false);

  return (
    <Box style={{ 
      textAlign: 'right',
      marginRight: '2px'
    }}>
      <Button
        color="primary"
        variant='contained'
        onClick={() => setAddApostasOpen(true)}
        style={{ marginRight: '10px' }}>
        + Adicionar Apostas
      </Button>
      <Button
        color="primary"
        variant='outlined'
        onClick={() => setAddBancaOpen(true)} 
        style={{ marginRight: '10px' }}>
        + Adicionar Banca
      </Button>

      <AddBet open={addApostasOpen} onClose={() => setAddApostasOpen(false)} />

      <AddBank open={addBancaOpen} onClose={() => setAddBancaOpen(false)} />
    </Box>
  );
};

export default DashboardHeader;
