import { Grid, LinearProgress, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import theme from '../../muiTheme';
import apiRequest from '../../srv/ApiRequest';

const StackedExample = () => {

  const [infoBets, setInfoBets] = useState(null);

  useEffect(() => {
    async function fetchInfoBets() {
      try {
        const response = await apiRequest('/bet/infoBets', 'GET');
        const data = await response.json();

        setInfoBets(data);
      } catch (error) {
        console.error('Erro ao buscar informações de apostas:', error);
      }
    }

    fetchInfoBets();
  }, []);

  const totalApostas = infoBets?.totalBets || 0;
  const totalApostasLucro = infoBets?.totalProfit || 0;
  const totalApostasPrejuizo = infoBets?.totalLoss || 0;

  const progressValue = totalApostas > 0 ? (totalApostasLucro / totalApostas) * 100 : 0;

  return (
    <Grid
      container
      spacing={2}
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid item xs={2}>
        <LinearProgress
          sx={{
            width: '25px',
            height: 350,
            borderRadius: 2,
            "& span.MuiLinearProgress-bar": {
              transform: `translateY(-${progressValue}%) !important`,
              backgroundColor: theme.palette.color3.main,
            },
            background: theme.palette.tertiary.main,
          }}
          variant="determinate"
          value={progressValue}
        />
      </Grid>
      <Grid item xs={10}>
        <Typography>
          Total de Apostas
          <br />
          {totalApostas}
          <br />
          Apostas com Lucro
          <br />
          {totalApostasLucro}
          <br />
          Apostas com Prejuízo
          <br />
          {totalApostasPrejuizo}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default StackedExample;
