import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Divider } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import apiRequest from '../../srv/ApiRequest';
import theme from '../../muiTheme';

const formatarData = (data) => {
  const date = new Date(data);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const BetList = () => {
  const [lastBets, setLastBets] = useState([]);

  useEffect(() => {
    async function fetchLastBets() {
      try {
        const response = await apiRequest('/bet/lastBets', 'GET');
        const data = await response.json();
        setLastBets(data);
      } catch (error) {
        console.error('Erro ao buscar as últimas apostas:', error);
      }
    }

    fetchLastBets();
  }, []);

  return (
    <div>
      <Typography variant="h6" textAlign="left">
        Ultimas Apostas
      </Typography>
      <Divider 
        style={{
          backgroundColor: theme.palette.primary.main,
          marginBottom: '10px',
        }}
      />
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Banca</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Retorno</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lastBets.map((bet) => (
            <TableRow key={bet.id}>
              <TableCell>{bet.nameBank}</TableCell>
              <TableCell>{formatarData(bet.dateOpe)}</TableCell>
              <TableCell>
                {bet.profit < 0 ? (
                  <ArrowDropDownIcon style={{ color: theme.palette.quaternary.main, }} />
                ) : (
                  <ArrowDropUpIcon style={{ color: theme.palette.tertiary.main, }} />
                )}
                R$ {bet.profit.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default BetList;
