import React, { useState, useEffect } from 'react';
import { Drawer, Button, TextField, Box, Typography, Select, MenuItem, Snackbar } from '@mui/material';
import theme from '../../muiTheme';
import apiRequest from '../../srv/ApiRequest';
import { reloadPage } from '../../srv/Reload';

const AddBet = ({ open, onClose }) => {
  
  const [valorInicial, setValorInicial] = useState('');
  const [valorFinal, setValorFinal] = useState('');
  const [dataOperacao, setDataOperacao] = useState('');
  const [bankId, setBankId] = useState(0);
  const [banks, setBanks] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');

  useEffect(() => {
    // Realize a solicitação GET para o endpoint e obtenha as opções de banco
    async function fetchBanks() {
      try {
        const response = await apiRequest('/bet/getBanksForUser', 'GET');
        const data = await response.json();

        setBanks(data);
      } catch (error) {
        console.error('Erro ao buscar opções de banco:', error);
      }
    }

    fetchBanks();
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {

    if (valorFinal.trim() === '' || valorInicial.trim() === '' || dataOperacao.trim() === '' || bankId === 0 ) {
      setSnackbarType('error');
      setSnackbarMessage('Por favor, preencha todos os campos.');
      setSnackbarOpen(true);
      return;
    }

    const data = {
      bankId: bankId,
      betTypeId: 0,
      dateOpe: dataOperacao,
      gameTypeId: 0,
      valueInitial: parseFloat(valorInicial.replace(',', '.')),
      valueFinal: parseFloat(valorFinal.replace(',', '.')),
    };

    apiRequest('/bet/saveOneBet', 'POST', data).then((response) => {
        
        if (response.ok) {

          setSnackbarType('success');
          setSnackbarMessage('Cadastro realizado com sucesso!');
          setSnackbarOpen(true);

          setValorInicial('');
          setValorFinal('');
          setDataOperacao('');
          setBankId(0);

          onClose();

          reloadPage();

        } else {
          console.error('Erro ao salvar operação:', response.statusText);
        }
      })
      .catch((error) => {
        console.error('Erro ao salvar operação:', error);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <div>
      <Drawer anchor="left" open={open} onClose={onClose}>
        <div
          style={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
            padding: theme.spacing(4),
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography style={{ fontSize: '20px', marginTop: '10px', color: theme.palette.primary.main, marginBottom: '20px'}}>
            Cadastrar Aposta
          </Typography>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Select
              value={bankId}
              onChange={(e) => setBankId(e.target.value)}
              style={{ marginBottom: '20px', width: '350px' }}
              variant="outlined" 
              inputProps={{ name: 'banca', id: 'banca-select' }}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem value={0}>Selecione a Banca</MenuItem>
              {banks.map((bank) => (
                <MenuItem key={bank.id} value={bank.id}>
                  {bank.name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <TextField
              label="Data da Aposta"
              variant="outlined"
              value={dataOperacao}
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setDataOperacao(e.target.value)}
              style={{ marginBottom: '20px', width: '350px' }}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <TextField
              label="Valor Inicial"
              variant="outlined"
              value={valorInicial}
              onChange={(e) => setValorInicial(e.target.value)}
              type="number"
              style={{ marginBottom: '20px', marginRight: '10px', width: '180px' }}
            />
            <TextField
              label="Valor Final"
              variant="outlined"
              value={valorFinal}
              onChange={(e) => setValorFinal(e.target.value)}
              type="number"
              style={{ marginBottom: '20px', width: '160px' }}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
              style={{ marginRight: '10px' }}
            >
              Salvar
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClose}
              style={{ marginRight: '10px' }}
            >
              Voltar
            </Button>
          </Box>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <Button color="secondary" size="small" onClick={handleSnackbarClose}>
            Fechar
          </Button>
        }
        ContentProps={{
          style: {
            backgroundColor: snackbarType === 'success' ? theme.palette.success.main : theme.palette.warning.main,
          },
        }}
      />
    </div>
  );
};

export default AddBet;
