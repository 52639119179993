import React from 'react';
import { createRoot } from 'react-dom'; // Importe o método createRoot
import App from './App';
import { ThemeProvider } from '@mui/material';
import theme from './muiTheme';

createRoot(document.getElementById('root')).render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>
);