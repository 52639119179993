import React, { useState } from 'react';
import { Drawer, Button, TextField, Box, Typography, Snackbar } from '@mui/material';
import theme from '../../muiTheme';
import apiRequest from '../../srv/ApiRequest';
import { reloadPage } from '../../srv/Reload';

const AddBank = ({ open, onClose }) => {

  const [nome, setNome] = useState('');
  const [valorInicial, setValorInicial] = useState('');
  const [dataInicial, setDataInicial] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    
    if (nome.trim() === '' || valorInicial.trim() === '' || dataInicial.trim() === '') {
      setSnackbarType('error');
      setSnackbarMessage('Por favor, preencha todos os campos.');
      setSnackbarOpen(true);
      return;
    }

    const bankData = {
      initialDate: dataInicial,
      name: nome,
      value: parseFloat(valorInicial.replace(',', '.')),
    };
  
    apiRequest('/bet/saveBank', 'POST', bankData).then((response) => {

        if (response.ok) {

          setSnackbarType('success');
          setSnackbarMessage('Cadastro realizado com sucesso!');
          setSnackbarOpen(true);

          setNome('');
          setValorInicial('');
          setDataInicial('');

          onClose();

          reloadPage();
          
        } else {
          console.error("Error saving bank:", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error saving bank:", error);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <div>
      <Drawer anchor="left" open={open} onClose={onClose}>
        <div
          style={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
            padding: theme.spacing(4),
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography style={{ fontSize: '20px', marginTop: '10px', color: theme.palette.primary.main, marginBottom: '20px'}}>
            Cadastrar Banca
          </Typography>

          <TextField
            label="Nome"
            variant="outlined"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            style={{ marginBottom: '20px', width:'350px' }}
          />

          <Box display="flex" justifyContent="flex-end" mt={2}>

              <TextField
              label="Valor Inicial"
              variant="outlined"
              value={valorInicial}
              onChange={(e) => setValorInicial(e.target.value)}
              type='number'
              style={{ marginBottom: '20px', marginRight: '10px', width:'180px' }}
              />
              <TextField
              label="Data Inicial"
              variant="outlined"
              value={dataInicial}
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setDataInicial(e.target.value)}
              style={{ marginBottom: '20px' }}
              />

          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
              style={{ marginRight: '10px' }}
            >
              Salvar
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClose}
              style={{ marginRight: '10px' }}
            >
              Voltar
            </Button>
          </Box>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <Button color="secondary" size="small" onClick={handleSnackbarClose}>
            Fechar
          </Button>
        }
        ContentProps={{
          style: {
            backgroundColor: snackbarType === 'success' ? theme.palette.success.main : theme.palette.warning.main,
          },
        }}
      />
    </div>
  );
};

export default AddBank;
