import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0D0D0D',
    },
    secondary: {
      main: '#FFFFFA',
    },
    tertiary: {
      main: '#25CB62',
    },
    quaternary: {
      main: '#D1462F',
    },
    color1: {
      main: '#202020',
    },
    color2: {
      main: '#696969',
    },
    color3: {
      main: '#878787',
    },
  },
});

export default theme;
