import React, { useState } from 'react';
import { Box, Grid, Hidden } from '@mui/material';
import theme from "../muiTheme";
import Header from './Header/Header';
import LiveGamesList from './LiveGames/LiveGamesList'; 
import BetDashboard from './Charts/BetDashboard';
import AnalyzesDashboard from './Charts/AnalyzesDashboard';

const DashboardPage = () => {
  const [activeDashboard, setActiveDashboard] = useState('bet');

  const handleBetDashboardClick = () => {
    setActiveDashboard('bet');
  };

  const handleAnalyzesDashboardClick = () => {
    setActiveDashboard('analyzes');
  };

  return (
    <Box
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <style>{customScrollbarStyle}</style>
      
      <Header 
        onBetDashboardClick={handleBetDashboardClick}
        onAnalyzesDashboardClick={handleAnalyzesDashboardClick}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          flex: 1,
          marginTop: '64px',
        }}
      >
        <Hidden smDown>
          <Grid 
            container
            style={{
              backgroundColor: theme.palette.secondary.main,
            }}
            spacing={3}
          >
            <Grid 
              item 
              xs={12} md={12}
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {activeDashboard === 'bet' ? <BetDashboard /> : <AnalyzesDashboard />}
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp>
        <Grid 
            container
            style={{
              backgroundColor: theme.palette.secondary.main,
            }}
            spacing={3}
          >
            <Grid 
              item 
              xs={12} md={12}
              style={{
                flex: 1,
              }}
            >
              <AnalyzesDashboard />
            </Grid>
          </Grid>
        </Hidden>
      </Box>
    </Box>
  );
};

const customScrollbarStyle = `
  ::-webkit-scrollbar {
    width: 12px; /* Largura da barra de rolagem */
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.primary.main}; /* Cor da barra de rolagem */
    border-radius: 6px; /* Arredonda as bordas da barra de rolagem */
  }
`;

export default DashboardPage;
