import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import theme from '../muiTheme';

import logo from '../images/logo_pequeno.png';
import imageOne from '../images/image_one.jpg';
import background from "../images/back.jpg";
import { Button, Grid, Hidden } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function Copyright() {
  return (
    <Typography variant="body2" color="secondary" align="center">
      {'Copyright © '}
      <Link color="secondary" href="https://sporthubpro.cloud">
        SportHub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Album() {

  const navigate = useNavigate();
  
  const handleGoToRegister = async () => {
    navigate('/register');
  };

  const handleGoToLogin = async () => {
    navigate('/login');
  };

  return (
    <Box
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(${background})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
      }}
    >
      <style>{customScrollbarStyle}</style>
      
      <AppBar position="relative">
        <Toolbar style={{ borderBottom: '1px solid #25CB62' }}>
          <img src={logo} alt="Logo" style={{ maxWidth: 35, marginLeft: '26px' }} />
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Button
                variant='outlined'
                onClick={handleGoToLogin}
                style={{ borderColor: `${theme.palette.primary.main}`, color: '#25CB62' }}
              >
                Login
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="tertiary"
                variant='contained'
                onClick={handleGoToRegister}
                style={{ color: `${theme.palette.primary.main}`, marginRight: '10px' }}
              >
                Cadastre-se
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <main>
        <Box
          sx={{
            pt: 20,
            pb: 8,
          }}
          style={{
            height: '80vh',
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h2"
              align="left"
              gutterBottom
              style={{ color: `${theme.palette.tertiary.main}`, }}
            >
              <img src={logo} alt="Logo" style={{ maxWidth: 45 }} /> SportHub
            </Typography>
            <Typography variant="h3" align="left" color="secondary" paragraph>
              Sua plataforma 
              <br></br>
              para controle de banca
            </Typography>
            <Button
              color="tertiary"
              variant='contained'
              onClick={handleGoToRegister}
              style={{ color: `${theme.palette.primary.main}`, marginTop: '26px' }}
            >
              Cadastre-se
            </Button>
          </Container>
        </Box>
        <Hidden smDown>
          <Box
            sx={{
              pt: 40,
            }}
            style={{
              alignItems: 'center',
              textAlign: 'center',
              backgroundColor: `${theme.palette.primary.main}`,
            }}
          >
            <Container maxWidth="xl">
              <Grid>
                <Grid 
                  item 
                  xs={12} 
                  md={12}
                  style={{
                    backgroundColor: `${theme.palette.tertiary.main}`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '200px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: `${theme.palette.tertiary.main}` }}>
                    <div style={{ flex: 1, textAlign: 'center', padding: '20px' }}>
                      <Typography variant="h4" align="center" color="secondary" paragraph style={{ color: `${theme.palette.primary.main}` }}>
                        <img src={imageOne} alt="Logo" style={{ width: '100%', borderRadius: '5px', }} />
                      </Typography>
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', padding: '10px' }}>
                      <Typography variant="h4" align="center" color="secondary" paragraph style={{ color: `${theme.palette.primary.main}`, marginTop: '5%', marginRight: '5%'}} >
                        Controle e analise os resultados de suas apostas, e acompanhe jogos do mundo inteiro 
                        <br></br> 
                        <b>ao vivo!</b>
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box
            sx={{
              pt: 40,
              pb: 20,
            }}
            style={{
              alignItems: 'center',
              textAlign: 'center',
              backgroundColor: `${theme.palette.primary.main}`,
            }}
          >
            <Container maxWidth="xl">
              <Grid>
                <Grid 
                  item 
                  xs={12} 
                  md={12}
                  style={{
                    backgroundColor: `${theme.palette.tertiary.main}`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '200px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: `${theme.palette.tertiary.main}` }}>
                    <div style={{ flex: 1, textAlign: 'center', padding: '10px' }}>
                      <Typography variant="h4" align="center" color="secondary" paragraph style={{ color: `${theme.palette.primary.main}` }}>
                        Acesse a plataforma
                      </Typography>
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', padding: '10px' }}>
                      <ArrowForwardIosIcon style={{ fontSize: '36px', color: `${theme.palette.primary.main}` }} />
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', padding: '10px' }}>
                      <Typography variant="h4" align="center" color="secondary" paragraph style={{ color: `${theme.palette.primary.main}` }}>
                        Cadastre sua banca e suas apostas
                      </Typography>
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', padding: '10px' }}>
                      <ArrowForwardIosIcon style={{ fontSize: '36px', color: `${theme.palette.primary.main}` }} />
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', padding: '10px' }}>
                      <Typography variant="h4" align="center" color="secondary" paragraph style={{ color: `${theme.palette.primary.main}` }}>
                        Analise seus ganhos
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
            <Container maxWidth="xl">
              <Grid>
                <Grid 
                  item 
                  xs={12} 
                  md={12}
                  style={{
                    backgroundColor: `${theme.palette.tertiary.main}`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '200px',
                  }}
                >
                  <Typography variant="h4" align="center" color="secondary" paragraph style={{ color: `${theme.palette.primary.main}`}} >
                    <b>Cadastre-se agora!</b>
                  </Typography>
                  <Button
                    color="primary"
                    variant='contained'
                    onClick={handleGoToRegister}
                    style={{ borderColor: `${theme.palette.primary.main}`, color: '#25CB62' }}
                  >
                    Cadastre-se
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Hidden>
      </main>
      <Box sx={{ p: 6 }} component="footer" style={{backgroundColor: `${theme.palette.primary.main}`, borderTop: '1px solid #25CB62' }}>
        <Copyright />
      </Box>
    </Box>
  );
}

const customScrollbarStyle = `
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.color1.main};
    border-radius: 6px;
  }
`;
