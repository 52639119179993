import React, { useState, useEffect } from 'react';
import { Typography, Divider, IconButton } from '@mui/material';
import theme from '../../muiTheme';
import { Box } from '@mui/system';
import { Wallet } from '@mui/icons-material';
import apiRequest from '../../srv/ApiRequest';

const TotalBanca = () => {
  const [totalBanca, setTotalBanca] = useState(null);

  const fetchTotalBanca = async () => {
    try {
      const response = await apiRequest('/bet/totalBank', 'GET');
      const data = await response.json();

      setTotalBanca(data.totalBank);
    } catch (error) {
      console.error('Erro ao buscar o total em banca:', error);
    }
  };

  useEffect(() => {
    fetchTotalBanca();
  }, []);

  const formattedTotalBanca = totalBanca !== null ? totalBanca.toFixed(2) : 'Carregando...';

  return (
    <>
      <Typography variant="h6">Total em Banca</Typography>
      <Divider
        style={{
          backgroundColor: theme.palette.primary.main,
          marginBottom: '10px',
        }}
      />
      <Box display="flex" alignItems="center">
        <IconButton
          style={{
            color: theme.palette.tertiary.main,
            marginRight: '5px',
          }}
        >
          <Wallet />
        </IconButton>
        <Typography variant="h5">
          R$ {formattedTotalBanca}
        </Typography>
      </Box>
    </>
  );
};

export default TotalBanca;
