import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Divider, Button, CircularProgress, DialogTitle, Dialog, DialogContent, DialogContentText, Backdrop, DialogActions } from '@mui/material';
import apiRequest from '../../srv/ApiRequest';
import theme from '../../muiTheme';
import TablePagination from '@mui/material/TablePagination';
import { Delete } from '@mui/icons-material';
import { reloadPage } from '../../srv/Reload';

const formatarData = (data) => {
  const date = new Date(data);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const DepositList = ({ bankId }) => {
  const [operations, setOperations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [operationIdToDelete, setOperationIdToDelete] = useState(null);

  useEffect(() => {
    async function fetchOperations() {
      try {
        const response = await apiRequest(`/bank/operationsForBank/${bankId}`, 'GET');
        const data = await response.json();
        setOperations(data);
      } catch (error) {
        console.error('Erro ao buscar as últimas operações:', error);
      }
    }

    fetchOperations();
  }, [bankId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRemoveBet = (operationId) => {
    setOperationIdToDelete(operationId);
    setShowConfirmationModal(true);
  };

  const confirmDeleteOperation = async () => {
    setShowConfirmationModal(false);
    setShowBackdrop(true);

    try {
      const response = await apiRequest(`/bank/deleteOperation/${bankId}/${operationIdToDelete}`, 'POST');
      if (response.ok) {
        reloadPage();
      } else {
        console.error(`Erro ao remover a operação com ID ${operationIdToDelete}:`, response.statusText);
      }
    } catch (error) {
      console.error(`Erro ao remover a operação com ID ${operationIdToDelete}:`, error);
    }
  };

  return (
    <div>
      <div>
        <Typography variant="h6" textAlign="left">
          Depósitos e Saques
        </Typography>
        <Divider 
          style={{
            backgroundColor: theme.palette.primary.main,
            marginBottom: '10px',
          }}
        />
      </div>
      <div style={{ height: 290 }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Total</TableCell>
              <TableCell  style={{ width: '5%' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? operations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : operations
            ).map((operation) => (
              <TableRow key={operation.id}>
                <TableCell>
                  {operation.operationType === "DEPOSIT" ? 
                    'Deposito'
                   : (
                    'Saque'
                  )}  
                </TableCell>
                <TableCell>{formatarData(operation.date)}</TableCell>
                <TableCell>
                  R$ {operation.value.toFixed(2)}
                </TableCell>
                <TableCell style={{ width: '5%' }}>
                  <Button
                    color="primary"
                    variant='outlined'
                    size='small'
                    style={{ minWidth: '24px', width: '24px', height: '24px', padding: '4px' }}
                    onClick={() => handleRemoveBet(operation.id)}
                  >
                    <Delete fontSize="small" style={{ fontSize: '16px' }} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div>
      <TablePagination
        component="div"
        count={operations.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
    </div>
    <Dialog
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmação</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza de que deseja excluir esta operação? Esta ação é irreversível.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmationModal(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDeleteOperation} color="error" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop open={showBackdrop} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DepositList;
