import React from 'react';
import { Box, Card, CardContent, Grid } from '@mui/material';
import theme from '../../muiTheme';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import DoughnutChart from './DoughnutChart';
import BankList from '../BankList/BankList';
import TotalLucro from '../Cards/TotalLucro';
import ValorTotalApostado from '../Cards/ValorTotalApostado';
import TotalBanca from '../Cards/TotalBanca';
import MediaLucro from '../Cards/MediaLucro';
import BetList from '../BetList/BetList';


const BetDashboard = () => {
  return (
    <Box
      style={{
        height: '100vh',
        padding: '10px',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DashboardHeader />
        </Grid>
        <Grid item xs={3}>
          <Card
            style={{
              backgroundColor: theme.palette.secondary.main,
              height: '100%',
              borderRadius: '10px',
            }}
          >
            <CardContent>
              <TotalBanca />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card
            style={{
              backgroundColor: theme.palette.secondary.main,
              height: '100%',
              borderRadius: '10px',
            }}
          >
            <CardContent>
              <ValorTotalApostado />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card
            style={{
              backgroundColor: theme.palette.secondary.main,
              height: '100%',
              borderRadius: '10px',
            }}
          >
            <CardContent>
              <TotalLucro />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card
            style={{
              backgroundColor: theme.palette.secondary.main,
              height: '100%',
              borderRadius: '10px',
            }}
          >
            <CardContent>
              <MediaLucro />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card
            style={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '10px',
              height: 400,
            }}
          >
            <CardContent>
              <DoughnutChart />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card
            style={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '10px',
              height: 400,
            }}
          >
            <CardContent>
              <BankList />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card
            style={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '10px',
              height: 400,
            }}
          >
            <CardContent>
              <BetList />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BetDashboard;
