import React, { useEffect, useState } from 'react';
import { List, ListItem, Divider, Typography, Box, Paper, Switch } from '@mui/material';
import apiRequest from '../../srv/ApiRequest';
import theme from '../../muiTheme';
import { format } from 'date-fns';

const LiveGamesList = () => {
  const [liveGames, setLiveGames] = useState([]);
  const [showLiveGamesOnly, setShowLiveGamesOnly] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiRequest('/realtime/getGamesToday', 'GET', null);
        const data = await response.json();
        setLiveGames(data.data);
      } catch (error) {
        console.error('Erro ao buscar os jogos ao vivo:', error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const sortedGames = liveGames
    .slice()
    .sort((a, b) => new Date(a.isoDate) - new Date(b.isoDate));

  const filteredGames = showLiveGamesOnly
    ? sortedGames.filter(
        (game) => game.gameTime !== 'Final' && game.gameTime !== 'Não Inic.'
      )
    : sortedGames;

  const toggleLiveGamesFilter = () => {
    setShowLiveGamesOnly(!showLiveGamesOnly);
  };

  return (
    <Box
      style={{
        height: '100vh',
        overflow: 'auto',
        padding: '1px',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <div style={{ color: `${theme.palette.primary.main}`, display: 'flex', alignItems: 'center', marginBottom: '5px', marginTop: '10px' }}>
        <Typography variant="subtitle2" color="priamry" style={{ marginRight: '1px', fontSize: '12px' }}>
          Ao vivo
        </Typography>
        <Switch
          color='primary'
          checked={showLiveGamesOnly}
          onChange={toggleLiveGamesFilter}
        />
      </div>

      <List sx={{ flexGrow: 1, alignItems: 'center', textAlign: 'center' }}>
        {filteredGames.map((game, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Divider sx={{ backgroundColor: `${theme.palette.primary.main}` }} />}
            <ListItem
              style={{
                padding: '1px',
                backgroundColor: `${theme.palette.secondary.main}`,
                color: `${theme.palette.primary.main}`,
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Paper
                style={{
                  padding: '1px',
                  backgroundColor: `${theme.palette.secondary.main}`,
                  color: `${theme.palette.primary.main}`,
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <Typography variant="subtitle1" color="primary" style={{ fontSize: '10px' }}>
                  {game.championship}
                </Typography>
                <Typography variant="subtitle2" color="primary" style={{ fontSize: '10px' }}>
                  {format(new Date(game.isoDate), 'dd/MM/yyyy - HH:mm')}
                </Typography>
                <Typography variant="body2" color="primary" style={{ fontSize: '10px', marginTop: '6px' }}>
                  {`${game.gameTime}`}
                </Typography>
                <Typography variant="body2" color="primary" style={{ fontSize: '10px', marginTop: '6px' }}>
                  {`${game.teamInitialsHome}`}&nbsp;
                  <img src={game.teamUrlLogoHome} alt={game.teamInitialsHome} style={{ width: '10px', height: '10px', marginLeft: '2px' }} />&nbsp;
                  {game.goalsHome !== null ? game.goalsHome : '-'}&nbsp;X&nbsp;{game.goalsAway !== null ? game.goalsAway : '-'}&nbsp;
                  <img src={game.teamUrlLogoAway} alt={game.teamInitialsAway} style={{ width: '10px', height: '10px', marginLeft: '2px' }} />&nbsp;
                  {`${game.teamInitialsAway}`}
                </Typography>
              </Paper>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default LiveGamesList;
