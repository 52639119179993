const BASE_URL = 'https://api.sporthubpro.cloud';

const apiRequest = async (path, method, body) => {
  const url = `${BASE_URL}${path}`;

  const token = localStorage.getItem('token');
  
  const headers = {
    'accept': '*/*',
    'Authorization': token,
    'Content-Type': 'application/json',
  };

  try {
    if (method === 'GET') {
      const response = await fetch(url, {
        method,
        headers,
      });
      if (response.ok) {
        return response;
      } else {
        throw new Error('Request failed');
      }
    } else {
      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(body),
      });
      if (response.ok) {
        return response;
      } else {
        throw new Error('Request failed');
      }
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export default apiRequest;
