import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  Drawer,
  Hidden,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import theme from "../../muiTheme";
import { Box } from '@mui/system';
import apiRequest from '../../srv/ApiRequest';

const appBarFixed = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
};

export const Header = ({ onBetDashboardClick, onAnalyzesDashboardClick }) => {

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [activeTab, setActiveTab] = useState('bet');
  const [userName, setUserName] = useState('');
  const [mail, setMail] = useState('');

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await apiRequest('/user/getUserInfos', 'GET', null);
        const data = await response.json();
        setUserName(data.name);
        setMail(data.username);
      } catch (error) {
        console.error('Erro ao buscar nome de usuário:', error);
      }
    };

    fetchUserName();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'bet') {
      onBetDashboardClick();
    } else if (tab === 'analyzes') {
      onAnalyzesDashboardClick();
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const handleSaveChanges = () => {
    // implementar a lógica para salvar as alterações do perfil

    // Depois de salvar, feche o Drawer
    setDrawerOpen(false);
  };

  return (
    <>
      <AppBar position="static"  style={appBarFixed}>
        <Toolbar style={{ borderBottom: '3px solid #0D0D0D', backgroundColor: theme.palette.secondary.main }}>

          <Hidden smDown>
            <Box style={{ textAlign: 'center', flex: 1, }}>
              <Button
                color="primary"
                variant={activeTab === 'bet' ? 'contained' : 'outlined'}
                onClick={() => handleTabChange('bet')}
                style={{
                  marginRight: '10px'
                }}
              >
                Banca
              </Button>
              <Button
                color="primary"
                variant= {activeTab === 'analyzes' ? 'contained' : 'outlined'}
                onClick={() => handleTabChange('analyzes')}
              >
                Análises
              </Button>
            </Box>
          </Hidden>

          <IconButton
            size="large"
            aria-label={`account of ${userName}`}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="primary"
            style={{ marginLeft: 'auto' }}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                backgroundColor: theme.palette.secondary.main,
              },
            }}
          >
            <MenuItem onClick={handleDrawerOpen} style={{ color: theme.palette.primary.main, }}>
              Perfil
            </MenuItem>
            <MenuItem onClick={handleLogout} style={{ color: theme.palette.primary.main, }}>
              Sair
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Divider />

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <div style={{
          width: '300px',
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.contrastText,
          padding: theme.spacing(4),
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Typography variant="h4" style={{ marginRight: 'auto', color: theme.palette.primary.main }}>
            {userName}
          </Typography>
          <Typography style={{ fontSize: '14px', marginTop: '10px', marginRight: 'auto', color: theme.palette.primary.main }}>
            {mail}
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="outlined"
              onClick={handleDrawerClose}
              color="primary"
              style={{ marginTop: '30px', }}
            >
              Voltar
            </Button>
          </Box>
        </div>
      </Drawer>

    </>
  );
};

export default Header;
