import React from 'react';
import { Box, Card, CardContent, Drawer, Grid } from '@mui/material';
import theme from '../../muiTheme';
import TotalLucro from '../CardsBank/TotalLucro';
import ValorTotalApostado from '../CardsBank/ValorTotalApostado';
import TotalBanca from '../CardsBank/TotalBanca';
import MediaLucro from '../CardsBank/MediaLucro';
import BetListForBank from '../BetList/BetListForBank';
import DepositList from '../DepositList/DepositList';
import BankHeader from '../BankHeader/BankHeader';

const BankStatistics = ({ open, onClose, bankId }) => {

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Drawer 
        anchor="bottom" 
        open={open} 
        onClose={onClose}
      >
        <div style={{ margin: '20px' }}> 
          <Box display="flex" justifyContent="flex-end">

            <BankHeader handleClose={handleClose} bankId={bankId}/>

          </Box>
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={3}>
              <Card
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <CardContent>
                  <TotalBanca bankId={bankId}/>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <CardContent>
                  <ValorTotalApostado bankId={bankId}/>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <CardContent>
                  <TotalLucro bankId={bankId}/>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <CardContent>
                  <MediaLucro bankId={bankId}/>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={8}>
              <Card
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: '10px',
                  height: 400,
                }}
              >
                <CardContent>
                  <BetListForBank bankId={bankId}/>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: '10px',
                  height: 400,
                }}
              >
                <CardContent>
                  <DepositList bankId={bankId}/>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </div>
  );
};

export default BankStatistics;
