import React, { useState, useEffect } from 'react';
import { Typography, Divider, IconButton } from '@mui/material';
import theme from '../../muiTheme';
import { Box } from '@mui/system';
import { AttachMoney } from '@mui/icons-material';
import apiRequest from '../../srv/ApiRequest';

const ValorTotalApostado = () => {

  const [valorTotalApostado, setValorTotalApostado] = useState(null);

  const fetchTotalApostado = async () => {
    try {
      const response = await apiRequest('/bet/totalBeting', 'GET');
      const data = await response.json();
      setValorTotalApostado(data.totalBeting);
    } catch (error) {
      console.error('Erro ao buscar o valor total apostado:', error);
    }
  };

  useEffect(() => {
    fetchTotalApostado();
  }, []);

  const formattedValorTotalApostado = valorTotalApostado !== null ? valorTotalApostado.toFixed(2) : 'Carregando...';

  return (
    <>
      <Typography variant="h6">Total Apostado</Typography>
      <Divider
        style={{
          backgroundColor: theme.palette.primary.main,
          marginBottom: '10px',
        }}
      />
      <Box display="flex" alignItems="center">
        <IconButton
          style={{
            color: theme.palette.tertiary.main,
            marginRight: '5px',
          }}
        >
          <AttachMoney />
        </IconButton>
        <Typography variant="h5">
          R$ {formattedValorTotalApostado}
        </Typography>
      </Box>
    </>
  );
};

export default ValorTotalApostado;
