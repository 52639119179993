import React, { useState, useEffect } from 'react';
import { Typography, Divider, IconButton } from '@mui/material';
import { ArrowUpward, ArrowDownward, East } from '@mui/icons-material';
import theme from '../../muiTheme';
import { Box } from '@mui/system';
import apiRequest from '../../srv/ApiRequest';

const MediaLucro = () => {
  const [mediaLucro, setMediaLucro] = useState(null);

  const lucroIconStyle = {
    color: theme.palette.tertiary.main,
    marginRight: '5px',
  };

  const perdaIconStyle = {
    color: theme.palette.quaternary.main,
    marginRight: '5px',
  };

  const zeroIconStyle = {
    color: theme.palette.primary.main,
    marginRight: '5px',
  };

  const renderIcon = (valor) => {
    if (valor > 0) {
      return (
        <IconButton style={lucroIconStyle}>
          <ArrowUpward />
        </IconButton>
      );
    } else if (valor < 0) {
      return (
        <IconButton style={perdaIconStyle}>
          <ArrowDownward />
        </IconButton>
      );
    } else {
      return (
        <IconButton style={zeroIconStyle}>
          <East />
        </IconButton>
      );
    }
  };

  useEffect(() => {
    async function fetchMediaLucro() {
      try {
        const response = await apiRequest('/bet/avgProfit', 'GET');
        const data = await response.json();

        setMediaLucro(data.avgProfit);
      } catch (error) {
        console.error('Erro ao buscar a média de lucro:', error);
      }
    }

    fetchMediaLucro();
  }, []);

  const formattedMediaLucro = mediaLucro !== null ? mediaLucro.toFixed(2) : 'Carregando...';

  return (
    <>
      <Typography variant="h6">Média de Retorno</Typography>
      <Divider
        style={{
          backgroundColor: theme.palette.primary.main,
          marginBottom: '10px',
        }}
      />
      <Box display="flex" alignItems="center">
        {mediaLucro !== null ? renderIcon(mediaLucro) : null}
        <Typography variant="h5">
          R$ {formattedMediaLucro}
        </Typography>
      </Box>
    </>
  );
};

export default MediaLucro;
