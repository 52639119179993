import React, { useState } from 'react';
import { Box, Typography, TextField, Button, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from "../muiTheme";
import logo from '../images/logo_pequeno.png';
import background from '../images/background.jpg';
import { useNavigate } from 'react-router-dom';
import apiRequest from '../srv/ApiRequest';

const RegisterPage = () => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [showRegistrationForm, setShowRegistrationForm] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const name = data.get('name');
    const password = data.get('password');

    const payload = {
      email,
      name,
      password
    };

    try {

      const response = await apiRequest('/user/register', 'POST', payload);

      if (response.status === 202) {
        setSuccessMessage('E-mail já cadastrado. Favor ir para a página de login.');
      } else {
        setShowRegistrationForm(false);
        setSuccessMessage('Cadastro realizado com sucesso! Favor ir para a página de login.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            flex: 1.5,
            backgroundColor: theme.palette.primary.main,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <img
            src={background}
            alt="Left Side"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
              
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ p: 1, width: '70%', mx: 'auto' }}>
            <Typography variant="h5" component="h2" color="primary" align="center">
              <img src={logo} alt="Logo" style={{ maxWidth: 45 }} />
            </Typography>
            <Typography variant="h5" component="h2" color="primary" align="center">
              Registro
            </Typography>
            {showRegistrationForm && (
              <form onSubmit={handleSubmit}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  color="primary"
                />
                <TextField
                  id="name"
                  name="name"
                  label="Nome"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  color="primary"
                />
                <TextField
                  id="password"
                  name="password"
                  label="Senha"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  color="primary"
                  type="password"
                />
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                  Cadastre-se
                </Button>
              </form>
            )}
            {successMessage && (
              <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
                {successMessage}
              </Typography>
            )}
            <Button
              className="secondary-button"
              onClick={handleLogin}
              fullWidth
              sx={{ mt: 2 }}
            >
              Login
            </Button>
          </Box>
          <style>
            {`
            .secondary-button {
              background-color: ${theme.palette.secondary.main};
              color: ${theme.palette.secondary.contrastText};
              border: 1px solid ${theme.palette.primary.main};
            }
            `}
          </style>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default RegisterPage;
