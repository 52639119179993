import React, { useEffect, useState } from 'react';
import apiRequest from '../../srv/ApiRequest.js';
import { format, addDays, subDays } from 'date-fns';
import { Button, Grid, Typography, Card, CardContent, Divider } from '@mui/material';
import { ptBR } from 'date-fns/locale';
import theme from '../../muiTheme.js';
import { Container } from '@mui/system';
import Statistics from '../Statistics/Statistics.js';

const TableGames = () => {

  const [dados, setDados] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [statistics, setStatistics] = useState(false);
  const [gameId, setGameId] = useState(0);

  const fetchData = async (selectedDate) => {
    try {
      const formattedDate = format(selectedDate, 'yyyyMMdd');
      const response = await apiRequest(`/realtime/soccer/scorepanel/${formattedDate}`, 'GET', null);
      const data = await response.json();

      if (data.code && data.code === 404) {
        
        setDados(null);

      } else {
        setDados(data.scores);
      }
      
    } catch (error) {
      console.error('Erro ao buscar os jogos ao vivo:', error);
    }
  };

  useEffect(() => {
    fetchData(selectedDate);
    const intervalId = setInterval(() => fetchData(selectedDate), 30000);

    return () => clearInterval(intervalId);
  }, [selectedDate]);

  const handleNextDate = () => {
    const nextDate = addDays(selectedDate, 1);
    setSelectedDate(nextDate);
  };

  const handlePrevDate = () => {
    const prevDate = subDays(selectedDate, 1);
    setSelectedDate(prevDate);
  };

  const handleStatistics = ( game ) => {
    setGameId(game);
    setStatistics(true)
  };

  const renderCalendar = () => {
    
    const days = [];
    for (let i = -1; i <= 1; i++) {
      const currentDate = addDays(selectedDate, i);
      const dayOfWeek = format(currentDate, 'EEEE', { locale: ptBR });
      const dayOfMonth = format(currentDate, 'dd MMM', { locale: ptBR });
  
      days.push(
        <div
          key={i}
          style={{
            width: '60px',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: i === 0 ? `${theme.palette.color3.main}` : 'transparent',
            border: '1px solid #ccc',
          }}
        >
          <Typography variant="subtitle1" style={{ fontSize: '10px' }}>
            {dayOfWeek}
            <br />
            {dayOfMonth}
          </Typography>
        </div>
      );
    }
  
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px'}}>
        <Button
          color="primary"
          variant="contained"
          style={{
            marginRight: '1px',
            width: '10px',
            height: '60px',
          }}
          onClick={handlePrevDate}
        >
          <Typography variant="subtitle1" style={{ fontSize: '15px' }}>{'<'}</Typography>
        </Button>
        {days}
        <Button
          color="primary"
          variant="contained"
          style={{
            marginRight: '1px',
            width: '10px',
            height: '60px',
          }}
          onClick={handleNextDate}
        >
          <Typography variant="subtitle1" style={{ fontSize: '15px' }}>{'>'}</Typography>
        </Button>
      </div>
    );
  };

  const renderTabela = () => {
  
    return (
      <Grid container>
        <Grid 
          item 
          xs={12} md={12}
          style={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6">
            Resultados - Futebol
          </Typography>
          {renderCalendar()}
        </Grid>

        {dados && dados.length !== 0 ? (
        <Grid 
          item 
          xs={12} md={12}
          style={{
            marginTop: '20px'
          }}
        >
          {dados.map((score, scoreIndex) => (
            <Grid 
              item 
              xs={12} md={12}
              style={{
                marginTop: '20px'
              }}
              key={scoreIndex}
            >
              <Typography variant="h6">
                {score.leagues[0]?.name || 'Nome da Liga Indisponível'}
              </Typography>

              {score.events?.map((evento, eventoIndex) => (
                <Card key={eventoIndex} sx={{ margin: '5px' }}>
                  <CardContent>
                    <Typography variant="body1" textAlign="left">
                      {format(new Date(evento.date), 'dd/MM/yyyy - HH:mm')}
                    </Typography>
                    <Typography variant="body2" textAlign="left">
                      {evento.gameTime}
                      {evento.competitions[0].venue && evento.competitions[0].venue.fullName ? (evento.competitions[0].venue.fullName) : ''} {' '} | {' '}
                      {evento.competitions[0].venue && evento.competitions[0].venue.address ? (
                        `${evento.competitions[0].venue.address.city}, ${evento.competitions[0].venue.address.country}`
                      ) : ''}
                    </Typography>

                    <Divider sx={{ backgroundColor: `${theme.palette.color3.main}` }} />
                    <Grid container>
                      <Grid item xs={6} textAlign="left" >
                        <Typography variant="body2">
                          {evento.status.type.state === 'in'
                          ? `${evento.status.displayClock} ${evento.status.period} T`
                          : evento.status.type.state === 'post'
                          ? 'Finalizado'
                          : <br/>}
                        </Typography>
                      
                        <Typography variant="body2">
                          <img src={evento.competitions[0].competitors[0].team.logo} alt={evento.teamInitialsHome} style={{ width: '20px', height: '20px' }} />&nbsp;
                          {evento.competitions[0].competitors[0].team.displayName}
                        </Typography>
                        <Typography variant="body2">
                          <img src={evento.competitions[0].competitors[1].team.logo} alt={evento.teamInitialsAway} style={{ width: '20px', height: '20px' }} />&nbsp;
                          {evento.competitions[0].competitors[1].team.displayName}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2">
                          <br/>
                        </Typography>
                        <Typography variant="body2">
                          {evento.status.type.state === 'in' || evento.status.type.state === 'post' ? evento.competitions[0].competitors[0].score : ' '}
                        </Typography>
                        <Typography variant="body2">
                          {evento.status.type.state === 'in' || evento.status.type.state === 'post' ? evento.competitions[0].competitors[1].score : ' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body2">
                          <br/>
                        </Typography>
                        <Button 
                          color="primary" 
                          variant="outlined"
                          onClick={() => handleStatistics( evento.id )} 
                        >
                          Resumo
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Grid>
          ))}
        </Grid>) : 
          <Grid 
            item 
            xs={12} md={12}
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body2"> Carregando... </Typography>
          </Grid>
         }

        <Statistics open={statistics} onClose={() => setStatistics(false)} gameId={gameId} />
        
      </Grid>
    );
  };

  return (
    <Container maxWidth="lg">
      <Grid>
        <Grid 
          item 
          xs={12} md={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {renderTabela()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default TableGames;
