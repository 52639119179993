import React, { useState } from 'react';
import { Box, Typography, TextField, Button, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from "../muiTheme";
import background from '../images/background.jpg';
import { useNavigate } from 'react-router-dom';
import apiRequest from '../srv/ApiRequest';
import logo from '../images/logo_pequeno.png';

const LoginPage = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');

    const payload = {
      email: username,
      pass: password
    };

    try {
      const response = await apiRequest('/auth', 'POST', payload);
  
      const data = await response.json();
      const { token } = data;
      localStorage.setItem('token', token);
      navigate('/dashboard');
    } catch (error) {
      setErrorMessage('Email ou senha incorretos');
    }
  };

  const handleRegister = () => {
    navigate('/register');
  };

  const handleGoToMainPage = async () => {
    navigate('/');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            flex: 1.5,
            backgroundColor: theme.palette.primary.main,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <img
            src={background}
            alt="Left Side"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
              
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ p: 1,  width: '70%', mx: 'auto' }}>
              <Typography variant="h5" component="h2" color="primary" align="center">
                <img src={logo} alt="Logo" style={{ maxWidth: 45 }} />
              </Typography>
              <Typography variant="h5" component="h2" color="primary" align="center">
                Login
              </Typography>
              {errorMessage && (
                <Typography variant="body2" color="error" fullWidth align="center" sx={{ mt: 2 }}>
                  {errorMessage}
                </Typography>
              )}
              <TextField
                id="username"
                name="username"
                label="Email"
                fullWidth
                margin="normal"
                variant="outlined"
                color="primary"
              />
              <TextField
                id="password"
                name="password"
                label="Senha"
                fullWidth
                margin="normal"
                variant="outlined"
                color="primary"
                type="password"
              />
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                Acessar
              </Button>
              <Button
                className="secondary-button"
                onClick={handleRegister}
                fullWidth
                sx={{ mt: 2 }}
              >
                Cadastre-se
              </Button>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button color="primary" onClick={handleGoToMainPage}>Página Principal</Button>
                <Button color="primary">Esqueci minha senha</Button>
              </Box>
            </Box>
          </form>
          <style>
            {`
            .secondary-button {
              background-color: ${theme.palette.secondary.main};
              color: ${theme.palette.secondary.contrastText};
              border: 1px solid ${theme.palette.primary.main};
            }
            `}
          </style>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LoginPage;
