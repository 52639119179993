import React from 'react';
import { Box } from '@mui/system';
import TableGames from '../TableGames/TableGames';

const AnalyzesDashboard = () => {

  return (
    <Box
      display="flex"
      justifyContent="center"
      textAlign="center"
      style={{
        height: '100vh',
      }}
    >
      <TableGames />
    </Box>
  );
};

export default AnalyzesDashboard;
