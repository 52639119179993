import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Typography, Button, Divider } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import theme from '../../muiTheme';
import apiRequest from '../../srv/ApiRequest';
import BankStatistics from '../BankStatistics/BankStatistics';

const BankList = () => {
  const [banks, setBanks] = useState([]);
  const [page, setPage] = useState(0);
  const [bankId, setBankId] = useState(0);
  const [statistics, setStatistics] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  const handleBank = ( bank ) => {
    setBankId(bank);
    setStatistics(true)
  };

  useEffect(() => {
    async function fetchBanks() {
      try {
        const response = await apiRequest('/bet/getBanksForUser', 'GET');
        const data = await response.json();

        setBanks(data);
      } catch (error) {
        console.error('Erro ao buscar bancas:', error);
      }
    }

    fetchBanks();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  return (
    <div>
      <div>
        <Typography variant="h6" textAlign="left">
          Bancas
        </Typography>
        <Divider 
          style={{
            backgroundColor: theme.palette.primary.main,
            marginBottom: '10px',
          }}
        />
      </div>
      <div style={{ height: 290 }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Tot.Depositado</TableCell>
              <TableCell>Banca Atual</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? banks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : banks
            ).map((bank) => (
              <TableRow key={bank.id}>
                <TableCell>{bank.name}</TableCell>
                <TableCell>R$ {bank.deposit !== null && bank.deposit !== undefined ? bank.deposit.toFixed(2) : 'N/A'}</TableCell>
                <TableCell>R$ {bank.wallet !== null && bank.wallet !== undefined ? bank.wallet.toFixed(2) : 'N/A'}</TableCell>
                <TableCell>
                  {bank.wallet !== null && bank.wallet !== undefined ? (
                    bank.deposit > bank.wallet ? (
                      <ArrowDropDownIcon color="error" />
                    ) : (
                      <ArrowDropUpIcon style={{ color: 'green' }} />
                    )
                  ) : null}
                </TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    variant='outlined'
                    style={{ width: '30px', height: '25px' }}
                    onClick={() => handleBank( bank.id )} 
                  >
                    Ver
                  </Button>
                </TableCell>
              </TableRow>
            ))}

            <BankStatistics  open={statistics} onClose={() => setStatistics(false)} bankId={bankId} />

          </TableBody>
        </Table>
      </div>
      <div>
        <TablePagination
          component="div"
          count={banks.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </div>
    </div>
  );
};

export default BankList;
