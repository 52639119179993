import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Typography, Button, Divider, Backdrop, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import theme from '../../muiTheme';
import apiRequest from '../../srv/ApiRequest';
import { Delete } from '@mui/icons-material';
import { reloadPage } from '../../srv/Reload';

const BetListForBank = ({ bankId }) => {
  const [betsForBank, setBetsForBank] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(6);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [betIdToDelete, setBetIdToDelete] = useState(null);

  useEffect(() => {
    async function fetchBanks() {
      try {
        const response = await apiRequest(`/bank/betsForBank/${bankId}`, 'GET');
        const data = await response.json();

        setBetsForBank(data);
      } catch (error) {
        console.error('Erro ao buscar bancas:', error);
      }
    }

    fetchBanks();
  }, [bankId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRemoveBet = async (betId) => {
    setBetIdToDelete(betId);
    setShowConfirmationModal(true);
  };

  const confirmDeleteBet = async () => {
    setShowBackdrop(true);
    setShowConfirmationModal(false);

    try {
      const response = await apiRequest(`/bet/deleteBet/${betIdToDelete}`, 'POST');
      if (response.ok) {
        reloadPage();
      } else {
        console.error(`Erro ao remover a aposta com ID ${betIdToDelete}:`, response.statusText);
      }
    } catch (error) {
      console.error(`Erro ao remover a aposta com ID ${betIdToDelete}:`, error);
    }
  };

  return (

    <div>
      <div>
        <Typography variant="h6" textAlign="left">
          Apostas
        </Typography>
        <Divider 
          style={{
            backgroundColor: theme.palette.primary.main,
            marginBottom: '10px',
          }}
        />
      </div>
      <div style={{ height: 290 }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Data Operação</TableCell>
              <TableCell>Valor Apostado</TableCell>
              <TableCell>Valor Final</TableCell>
              <TableCell>Retorno</TableCell>
              <TableCell style={{ width: '5%' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? betsForBank.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : betsForBank
            ).map((bet) => (
              <TableRow key={bet.id}>
                <TableCell>{new Date(bet.dateOpe).toLocaleDateString()}</TableCell>
                <TableCell>R$ {bet.valueInitial !== null && bet.valueInitial !== undefined ? bet.valueInitial.toFixed(2) : 'N/A'}</TableCell>
                <TableCell>R$ {bet.valueFinal !== null && bet.valueFinal !== undefined ? bet.valueFinal.toFixed(2) : 'N/A'}</TableCell>
                <TableCell>R$ {bet.profit !== null && bet.profit !== undefined ? bet.profit.toFixed(2) : 'N/A'}</TableCell>
                <TableCell style={{ width: '5%' }}>
                  <Button
                    color="primary"
                    variant='outlined'
                    size='small'
                    style={{ minWidth: '24px', width: '24px', height: '24px', padding: '4px' }}
                    onClick={() => handleRemoveBet(bet.id)}
                  >
                    <Delete fontSize="small" style={{ fontSize: '16px' }} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div>
        <TablePagination
          component="div"
          count={betsForBank.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </div>
      {showBackdrop && (
        <Backdrop open={showBackdrop} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    <Dialog
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmação</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza de que deseja excluir os dados desta aposta? Esta ação é irreversível.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmationModal(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDeleteBet} color="error" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BetListForBank;
