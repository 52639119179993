import React, { useState } from 'react';
import { Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Backdrop, TextField, Snackbar } from '@mui/material';
import apiRequest from '../../srv/ApiRequest';
import { reloadPage } from '../../srv/Reload';
import theme from '../../muiTheme';

const BankHeader = ({ handleClose, bankId }) => {

  const [openModal, setOpenModal] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);

  const [operationType, setOperationType] = useState('DEPOSIT');
  const [date, setDate] = useState('');
  const [value, setValue] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleRemoveBank = () => {
    setOpenModal(true);
  };

  const handleConfirmRemoveBank = () => {
    setShowBackdrop(true);

    apiRequest(`/bank/deleteBank/${bankId}`, 'POST')
      .then(response => {
        if (response.ok) {
          reloadPage();
        } else {
          console.error('Erro ao remover a banca:', response.statusText);
        }
      })
      .catch(error => {
        console.error('Erro ao remover a banca:', error);
      })
      .finally(() => {
        setOpenModal(false);
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenDepositModal = () => {
    setShowDepositModal(true);
  };

  const handleCloseDepositModal = () => {
    setShowDepositModal(false);
  };

  const handleSave = () => {

    setSnackbarOpen(false);

    if (!date || !value || !operationType) {
      setSnackbarOpen(true);
      return;
    }

    setShowDepositModal(false);
    setShowBackdrop(true);

    const data = {
      bank_id: bankId,
      date: date,
      operationType: operationType,
      value: parseFloat(value)
    };

    apiRequest('/bank/saveOperationsForBank', 'POST', data)
      .then(response => {
        if (response.ok) {
          reloadPage();
        } else {
          console.error('Erro ao salvar a operação:', response.statusText);
        }
      })
      .catch(error => {
        console.error('Erro ao salvar a operação:', error);
      });
  };


  return (
    <Box display="flex" justifyContent="space-between">
      <Button
        color="error"
        variant="contained"
        style={{ marginRight: '10px' }}
        onClick={handleRemoveBank} 
      >
        Remover Banca
      </Button>

      <Box display="flex">
        <Button
          color="primary"
          variant='contained'
          style={{ marginRight: '10px', textAlign: 'right' }}
          onClick={handleOpenDepositModal}
        >
          + Adicionar Saque/Depósito
        </Button>

        <Button
          color="primary"
          variant="outlined"
          onClick={handleClose}
          style={{ textAlign: 'right' }}
        >
          X
        </Button>
      </Box>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmar exclusão da banca"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza de que deseja excluir todos os dados desta banca? Essa ação é irreversível.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmRemoveBank} color="error" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDepositModal}
        onClose={handleCloseDepositModal}
        aria-labelledby="deposit-modal-title"
        aria-describedby="deposit-modal-description"
      >
        <DialogTitle id="deposit-modal-title">{"Adicionar Saque/Depósito"}</DialogTitle>

        <DialogContent>
          <div
            style={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.contrastText,
              padding: theme.spacing(4),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box width="100%" display="flex" justifyContent="center">
              <TextField
                select
                label="Tipo"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                SelectProps={{
                  native: true,
                }}
                value={operationType}
                onChange={(e) => setOperationType(e.target.value)} 
              >
                <option value="DEPOSIT">Depósito</option>
                <option value="WITHDRAW">Saque</option>
              </TextField>
            </Box>
            <TextField
              label="Data"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              style={{ width: '100%', marginBottom: '20px' }}
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <TextField
              label="Valor"
              variant="outlined"
              type="number"
              style={{ width: '100%', marginBottom: '20px' }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleCloseDepositModal}
              style={{ marginRight: '10px' }}
            >
              Voltar
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
              style={{ marginRight: '10px' }}
            >
              Salvar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {showBackdrop && (
        <Backdrop open={showBackdrop} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message="Por favor, preencha todos os campos."
      />

    </Box>
  );
};

export default BankHeader;
