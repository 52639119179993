import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Divider, Drawer, Grid, IconButton, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import theme from '../../muiTheme';
import apiRequest from '../../srv/ApiRequest';
import { format } from 'date-fns';
import { Place, Stadium } from '@mui/icons-material';

const Statistics = ({ open, onClose, gameId }) => {

  const [gameData, setGameData] = useState(null);
  const [possession, setPossession] = useState(0);

  useEffect(() => {

    async function fetchGameData() {

      if (open && gameId) {
        try {
          const response = await apiRequest(`/realtime/soccer/game/${gameId}`, 'GET');
          const data = await response.json();
          setGameData(data);
        } catch (error) {
          console.error('Erro ao buscar os dados do jogo:', error);
        }
      }
    }

    fetchGameData();
  }, [open, gameId]);

  useEffect(() => {
    if (gameData && gameData.boxscore && gameData.boxscore.teams) {
      const possessionStat = gameData.boxscore.teams[1].statistics.find(stat => stat.name === 'possessionPct');
  
      if (possessionStat) {
        setPossession(parseFloat(possessionStat.displayValue));
      }
    }
  }, [gameData]);
  

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Drawer 
        anchor="bottom" 
        open={open} 
        onClose={onClose}
      >
        <div>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClose}
              style={{ margin: '10px' }}
            >
              X
            </Button>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} textAlign="center" >
              <Card
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: '10px',
                  margin:'10px',
                }}
              >
                {gameData && gameData.header ? (
                  <Typography variant="h6">
                    {gameData.header.season.name}
                  </Typography>
                ) : (
                  <Typography variant="body1">Carregando...</Typography>
                )}

                <Divider sx={{ backgroundColor: `${theme.palette.color3.main}` }}  style={{ marginTop: '8px' }}  />
                
                <Grid container spacing={2} alignItems="center" justifyContent="center"  style={{ marginTop: '8px' }} >
                  {gameData && gameData.boxscore ? (
                    <>
                      <Grid item xs={4} textAlign="center">
                        <div>
                          {gameData.header.competitions[0].competitors[0].team.logos[0].href && (
                            <img src={gameData.header.competitions[0].competitors[0].team.logos[0].href} alt={gameData.header.competitions[0].competitors[0].team.displayName} style={{ width: '50px', height: '50px' }} />
                          )}
                          <Typography variant="h6">
                            {gameData.header.competitions[0].competitors[0].team.displayName}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={1} textAlign="center">
                        <Typography variant="h6">
                          {gameData.header.competitions[0].status.type.state === 'in' || gameData.header.competitions[0].status.type.state === 'post' ? gameData.header.competitions[0].competitors[0].score : ' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} textAlign="center">
                        <div>
                          {gameData.header && gameData.header.competitions[0] ? (
                            <Typography variant="body1">
                              {format(new Date(gameData.header.competitions[0].date), 'HH:mm')}
                            </Typography>
                          ) : (
                            <Typography variant="body1">Data não disponível</Typography>
                          )}
                        </div>
                        <Typography variant="body2">
                          {gameData.header.competitions[0].status.type.state === 'in'
                          ? `${gameData.header.competitions[0].status.displayClock} ${gameData.header.competitions[0].status.period} T`
                          : gameData.header.competitions[0].status.type.state === 'post'
                          ? 'Finalizado'
                          : <br/>}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} textAlign="center">
                        <Typography variant="h6">
                          {gameData.header.competitions[0].status.type.state === 'in' || gameData.header.competitions[0].status.type.state === 'post' ? gameData.header.competitions[0].competitors[1].score : ' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} textAlign="center">
                        <div>
                          {gameData.header.competitions[0].competitors[1].team.logos[0].href && (
                            <img src={gameData.header.competitions[0].competitors[1].team.logos[0].href} alt={gameData.header.competitions[0].competitors[1].team.displayName} style={{ width: '50px', height: '50px' }} />
                          )}
                          <Typography variant="h6">
                            {gameData.header.competitions[0].competitors[1].team.displayName}
                          </Typography>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <Typography variant="body1">Carregando...</Typography>
                  )}
                </Grid>

                <Divider sx={{ backgroundColor: `${theme.palette.color3.main}` }} style={{ marginTop: '10px' }} />

                {gameData && gameData.gameInfo ? (
                  <>
                    <Typography variant="body2" textAlign="left"  style={{ marginTop: '5px' }} >
                      {gameData.gameInfo.venue && gameData.gameInfo.venue.fullName ? (
                        <>
                          <IconButton
                            size="small"
                            aria-label="Stadium"
                            color="primary"
                          >
                            <Stadium />
                          </IconButton>
                          {gameData.gameInfo.venue.fullName}
                        </>
                      ) : ''}
                    </Typography>
                    <Typography variant="body2" textAlign="left">
                      {gameData.gameInfo.venue && gameData.gameInfo.venue.address.country && gameData.gameInfo.venue.address.city ? (
                        <>
                          <IconButton
                            size="small"
                            aria-label="Place"
                            color="primary"
                          >
                            <Place />
                          </IconButton>
                          { gameData.gameInfo.venue.address.city + ' - '+ gameData.gameInfo.venue.address.country }
                        </>
                      ) : ''}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body1">Carregando...</Typography>
                )}                
              </Card>
            </Grid>

            { gameData &&
              gameData.boxscore &&
              gameData.boxscore.teams &&
              gameData.boxscore.teams[1] &&
              gameData.boxscore.teams[1].statistics &&
              gameData.boxscore.teams[0] &&
              gameData.boxscore.teams[0].statistics ? (
                <Grid item xs={12} md={6} lg={4} alignItems="center" textAlign="center">
                  <Card
                    style={{
                      backgroundColor: theme.palette.secondary.main,
                      height: '100%',
                      borderRadius: '10px',
                      margin: '10px',
                      maxHeight: '480px',
                      overflowY: 'auto',
                    }}
                  >
                    <CardContent>
                      <>
                        <Typography variant="body1" textAlign="left">
                          Estatísticas
                        </Typography>
                        <Divider sx={{ backgroundColor: `${theme.palette.color3.main}` }} />
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">
                                  <img src={gameData.boxscore.teams[1].team.logo} alt={gameData.boxscore.teams[1].team.abbreviation} style={{ width: '20px', height: '20px' }} />&nbsp;
                                  <Typography variant="body2">{gameData.boxscore.teams[1].team.abbreviation}</Typography>
                                </TableCell>
                                
                                {gameData.boxscore.teams[1].statistics.find(stat => stat.name === 'possessionPct') ? (
                                  <TableCell align="center">
                                    <LinearProgress
                                      variant="determinate"
                                      value={parseFloat(possession)}
                                      style={{ width: '80%', margin: 'auto' }}
                                    />
                                    <Typography variant="body2">{parseFloat(possession).toFixed(2) + '%' + ' | ' + (100 - parseFloat(possession)).toFixed(2) + '%'}</Typography>
                                  </TableCell>
                                ) : <TableCell align="center"></TableCell> }

                                <TableCell align="center">
                                  <img src={gameData.boxscore.teams[0].team.logo} alt={gameData.boxscore.teams[0].team.abbreviation} style={{ width: '20px', height: '20px' }} />&nbsp;
                                  <Typography variant="body2">{gameData.boxscore.teams[0].team.abbreviation}</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {gameData.boxscore.teams[1].statistics.map((stat, index) => {
                                if (stat.name !== 'possessionPct') {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell align="center">
                                        <Typography variant="body2">{stat.displayValue}</Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography variant="body2">{stat.label}</Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography variant="body2">{gameData.boxscore.teams[0].statistics[index].displayValue}</Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                                return null;
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    </CardContent>
                  </Card>
                </Grid>
              ) : null}


            {gameData && gameData.leaders && gameData.leaders[0] && gameData.leaders[0].leaders[0] && gameData.leaders[1].leaders[0] && gameData.leaders[0].team && gameData.leaders[1] && gameData.leaders[1].team ? (
              <Grid item xs={12} md={6} lg={4} alignItems="center" textAlign="center">
                <Card
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    height: '100%',
                    borderRadius: '10px',
                    margin: '10px',
                  }}
                >
                  <CardContent>
                    <>
                      <Typography variant="body1" textAlign="left">
                        Artilheiros
                      </Typography>
                      <Divider sx={{ backgroundColor: `${theme.palette.color3.main}` }} />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <img src={gameData.leaders[0].team.logo} alt={gameData.leaders[0].team.abbreviation} style={{ width: '20px', height: '20px' }} />&nbsp;
                                    {gameData.leaders[0].team.abbreviation}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {gameData.leaders[0].leaders[0].leaders.map((leader, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Typography variant="subtitle1" fontWeight="bold">
                                        {leader.athlete.displayName}
                                      </Typography>
                                      <Typography variant="body2">
                                        {leader.displayValue}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        <Grid item xs={6}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <img src={gameData.leaders[1].team.logo} alt={gameData.leaders[1].team.abbreviation} style={{ width: '20px', height: '20px' }} />&nbsp;
                                    {gameData.leaders[1].team.abbreviation}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {gameData.leaders[1].leaders[0].leaders.map((leader, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Typography variant="subtitle1" fontWeight="bold">
                                        {leader.athlete.displayName}
                                      </Typography>
                                      <Typography variant="body2">
                                        {leader.displayValue}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {gameData && gameData.leaders && gameData.leaders[0] && gameData.leaders[0].leaders[1] && gameData.leaders[1].leaders[1] && gameData.leaders[0].team && gameData.leaders[1] && gameData.leaders[1].team ? (
              <Grid item xs={12} md={6} lg={4} alignItems="center" textAlign="center">
                <Card
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    height: '100%',
                    borderRadius: '10px',
                    margin: '10px',
                  }}
                >
                  <CardContent>
                    <>
                      <Typography variant="body1" textAlign="left">
                        Mais Assistências
                      </Typography>
                      <Divider sx={{ backgroundColor: `${theme.palette.color3.main}` }} />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <img src={gameData.leaders[0].team.logo} alt={gameData.leaders[0].team.abbreviation} style={{ width: '20px', height: '20px' }} />&nbsp;
                                    {gameData.leaders[0].team.abbreviation}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {gameData.leaders[0].leaders[1].leaders.map((leader, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Typography variant="subtitle1" fontWeight="bold">
                                        {leader.athlete.displayName}
                                      </Typography>
                                      <Typography variant="body2">
                                        {leader.displayValue}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        <Grid item xs={6}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <img src={gameData.leaders[1].team.logo} alt={gameData.leaders[1].team.abbreviation} style={{ width: '20px', height: '20px' }} />&nbsp;
                                    {gameData.leaders[1].team.abbreviation}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {gameData.leaders[1].leaders[1].leaders.map((leader, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Typography variant="subtitle1" fontWeight="bold">
                                        {leader.athlete.displayName}
                                      </Typography>
                                      <Typography variant="body2">
                                        {leader.displayValue}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}

            {gameData && gameData.boxscore && gameData.boxscore.teams[0].statistics && gameData.boxscore.teams[1].statistics && gameData.headToHeadGames && gameData.headToHeadGames[0] && gameData.headToHeadGames[0].events ? (
              <Grid item xs={12} md={6} lg={4} alignItems="center" textAlign="center">
                <Card
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    height: '100%',
                    borderRadius: '10px',
                    margin: '10px',
                  }}
                >
                  <CardContent>
                    <>
                      <Typography variant="body1" textAlign="left">
                        Confronto direto
                      </Typography>
                      <Divider sx={{ backgroundColor: `${theme.palette.color3.main}` }} />

                      <TableContainer>
                        <Table>
                          <TableBody>
                            {gameData.headToHeadGames[0].events.map((event, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {event.atVs === 'em' && (
                                    <>
                                      <img src={event.opponentLogo} alt="Opponent Logo" style={{ width: '20px', height: '20px' }} />&nbsp;
                                      {event.homeTeamScore} - {event.awayTeamScore} &nbsp;
                                      <img src={gameData.headToHeadGames[0].team.logo} alt={gameData.headToHeadGames[0].team.displayName} style={{ width: '20px', height: '20px' }} />
                                    </>
                                  )}
                                  {event.atVs !== 'em' && (
                                    <>
                                      <img src={gameData.headToHeadGames[0].team.logo} alt={gameData.headToHeadGames[0].team.displayName} style={{ width: '20px', height: '20px' }} />&nbsp;
                                      {event.homeTeamScore} - {event.awayTeamScore} &nbsp;
                                      <img src={event.opponentLogo} alt="Opponent Logo" style={{ width: '20px', height: '20px' }} />
                                    </>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {format(new Date(event.gameDate), 'dd/MM/yyyy')}
                                </TableCell>
                                <TableCell>
                                  {event.leagueName}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}

          {gameData && gameData.boxscore && gameData.boxscore.teams[0].statistics && gameData.boxscore.teams[1].statistics && gameData.boxscore.form && gameData.boxscore.form[0] && gameData.boxscore.form[0].events ? (
              <Grid item xs={12} md={6} lg={4} alignItems="center" textAlign="center">
                <Card
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    height: '100%',
                    borderRadius: '10px',
                    margin: '10px',
                  }}
                >
                  <CardContent>
                    <>
                      <Typography variant="body1" textAlign="left">
                        <img src={gameData.boxscore.form[1].team.logo} alt={gameData.boxscore.form[1].team.displayName} style={{ width: '20px', height: '20px' }} /> &nbsp; Últimas partidas
                      </Typography>
                      <Divider sx={{ backgroundColor: `${theme.palette.color3.main}` }} />

                      <TableContainer>
                        <Table>
                          <TableBody>
                            {gameData.boxscore.form[1].events.map((event, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {event.atVs === 'em' && (
                                    <>
                                      <img src={event.opponentLogo} alt="" style={{ width: '20px', height: '20px' }} />&nbsp;
                                      {event.homeTeamScore} - {event.awayTeamScore} &nbsp;
                                      <img src={gameData.boxscore.form[1].team.logo} alt={gameData.boxscore.form[1].team.displayName} style={{ width: '20px', height: '20px' }} />
                                    </>
                                  )}
                                  {event.atVs !== 'em' && (
                                    <>
                                      <img src={gameData.boxscore.form[1].team.logo} alt={gameData.boxscore.form[1].team.displayName} style={{ width: '20px', height: '20px' }} />&nbsp;
                                      {event.homeTeamScore} - {event.awayTeamScore} &nbsp;
                                      <img src={event.opponentLogo} alt="" style={{ width: '20px', height: '20px' }} />
                                    </>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {format(new Date(event.gameDate), 'dd/MM/yyyy')}
                                </TableCell>
                                <TableCell>
                                  {event.leagueName}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}

            {gameData && gameData.boxscore && gameData.boxscore.teams[0].statistics && gameData.boxscore.teams[1].statistics && gameData.boxscore.form && gameData.boxscore.form[0] && gameData.boxscore.form[0].events ? (
              <Grid item xs={12} md={6} lg={4} alignItems="center" textAlign="center">
                <Card
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    height: '100%',
                    borderRadius: '10px',
                    margin: '10px',
                  }}
                >
                  <CardContent>
                    <>
                      <Typography variant="body1" textAlign="left">
                        <img src={gameData.boxscore.form[0].team.logo} alt={gameData.boxscore.form[0].team.displayName} style={{ width: '20px', height: '20px' }} /> &nbsp; Últimas partidas
                      </Typography>
                      <Divider sx={{ backgroundColor: `${theme.palette.color3.main}` }} />

                      <TableContainer>
                        <Table>
                          <TableBody>
                            {gameData.boxscore.form[0].events.map((event, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {event.atVs === 'em' && (
                                    <>
                                      <img src={event.opponentLogo} alt="" style={{ width: '20px', height: '20px' }} />&nbsp;
                                      {event.homeTeamScore} - {event.awayTeamScore} &nbsp;
                                      <img src={gameData.boxscore.form[0].team.logo} alt={gameData.boxscore.form[0].team.displayName} style={{ width: '20px', height: '20px' }} />
                                    </>
                                  )}
                                  {event.atVs !== 'em' && (
                                    <>
                                      <img src={gameData.boxscore.form[0].team.logo} alt={gameData.boxscore.form[0].team.displayName} style={{ width: '20px', height: '20px' }} />&nbsp;
                                      {event.homeTeamScore} - {event.awayTeamScore} &nbsp;
                                      <img src={event.opponentLogo} alt="" style={{ width: '20px', height: '20px' }} />
                                    </>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {format(new Date(event.gameDate), 'dd/MM/yyyy')}
                                </TableCell>
                                <TableCell>
                                  {event.leagueName}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}

          </Grid>
        </div>
      </Drawer>
    </div>
  );
};

export default Statistics;
