import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import DashboardPage from './components/DashboardPage';
import { ThemeProvider } from 'styled-components';
import { CssBaseline } from '@mui/material';
import theme from './muiTheme';

const App = () => {

  useEffect(() => {
    document.title = "SportHub";
  }, []);

  const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return !!token; 
  };

  const ProtectedRoute = ({
    redirectPath = '/login',
    children,
  }) => {

    if (!isAuthenticated()) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="dashboard"
              element={
                <ProtectedRoute >
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
