import React, { useState, useEffect } from 'react';
import { Typography, Divider, Box, IconButton } from '@mui/material';
import { ArrowUpward, ArrowDownward, East } from '@mui/icons-material';
import theme from '../../muiTheme';
import apiRequest from '../../srv/ApiRequest';

const TotalLucro = ( {bankId} ) => {
  const [lucroTotal, setLucroTotal] = useState(null);

  const lucroIconStyle = {
    color: theme.palette.tertiary.main,
    marginRight: '5px',
  };

  const perdaIconStyle = {
    color: theme.palette.quaternary.main,
    marginRight: '5px',
  };

  const zeroIconStyle = {
    color: theme.palette.primary.main,
    marginRight: '5px',
  };

  const renderIcon = (valor) => {
    if (valor > 0) {
      return (
        <IconButton style={lucroIconStyle}>
          <ArrowUpward />
        </IconButton>
      );
    } else if (valor < 0) {
      return (
        <IconButton style={perdaIconStyle}>
          <ArrowDownward />
        </IconButton>
      );
    } else {
      return (
        <IconButton style={zeroIconStyle}>
          <East />
        </IconButton>
      );
    }
  };

  useEffect(() => {

    async function fetchTotalProfit() {

      try {
        const response = await apiRequest(`/bank/totalProfit/${bankId}`, 'GET');
        const data = await response.json();

        setLucroTotal(data.totalProfit); 
      } catch (error) {
        console.error('Error fetching total profit:', error);
      }
    }

    fetchTotalProfit();
  }, []);

  const formattedLucroTotal = lucroTotal !== null ? lucroTotal.toFixed(2) : 'Carregando...';

  return (
    <>
      <Typography variant="h6">Retorno Total</Typography>
      <Divider
        style={{
          backgroundColor: theme.palette.primary.main,
          marginBottom: '10px',
        }}
      />
      <Box display="flex" alignItems="center">
        {lucroTotal !== null ? renderIcon(lucroTotal) : null}
        <Typography variant="h5">
          R$ {lucroTotal !== null ? formattedLucroTotal : 'Carregando...'}
        </Typography>
      </Box>
    </>
  );
};

export default TotalLucro;
